import React, { useEffect, useState } from "react";
import { Button, Col, ListGroup, Row, Tab, Tabs } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { Logger } from "aws-amplify";
import dayjs from "dayjs";
import TextLabel from "../../Shared/components/TextLabel";
import { IconSVG } from "../../Shared/components/IconSVG";
import { openFileUrl } from "../../Shared/helpers/file";
import { useNotifier } from "../../Shared/hooks/useNotifier";
import { useGraphQL } from "../../Shared/hooks";
import * as queries from "graphql-custom/queries";
import * as mutations from "graphql-custom/mutations";
import { ConsultationFormModal } from "../ConsultationForm/ConsultationFormModal";

const logger = new Logger("ConsultationDetailPage");

export const ConsultationDetailPage = () => {
  const { id } = useParams();
  const history = useHistory();
  const { loading, runGraphQLOperation } = useGraphQL();
  const { loading: downloading, runGraphQLOperation: generatePrescription } = useGraphQL();
  const [consultation, setConsultation] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const { showError } = useNotifier();

  useEffect(() => {
    getConsultationDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getConsultationDetails = async () => {
    try {
      const response = await runGraphQLOperation({
        operation: queries.getConsultation,
        variables: { id },
      });
      setConsultation(response.getConsultation);
    } catch (error) {
      logger.error(error);
      showError("Error al cargar la información de la consulta");
    }
  };

  const downloadPrescription = async () => {
    try {
      const input = { consultationID: id };
      const { generatePrescription: response } = await generatePrescription({
        operation: mutations.generatePrescription,
        variables: { input },
      });
      openFileUrl(response.fileUrl);
    } catch (error) {
      logger.error(error);
      showError("Ocurrió un error durante la generación de la receta");
    }
  };

  const goBack = () => {
    history.goBack();
  };

  const showModal = () => {
    setOpenModal(true);
  };

  const hideModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div className="card card-custom">
        <div className="card-header">
          <div className="card-title">
            <Button variant="link" className="px-0 mr-4" onClick={goBack}>
              <IconSVG absolutePath="/media/svg/icons/Navigation/Close.svg" />
            </Button>
            <h3 className="card-label">Detalles de Consulta</h3>
          </div>
          <div className="card-toolbar">
            <Button variant="primary" className="btn-danger" onClick={downloadPrescription} disabled={downloading}>
              <IconSVG color="#fff" absolutePath="/media/svg/icons/Files/File.svg" />
              <span>Generar Receta</span>
              {downloading && <span className="ml-3 mr-3 spinner spinner-white" />}
            </Button>
            <Button variant="warning" className="btn-primary ml-4" onClick={showModal}>
              <IconSVG color="#fff" absolutePath="/media/svg/icons/Design/Edit.svg" />
              <span>Modificar</span>
            </Button>
          </div>
        </div>
        <div className="card-body container-sm">
          <Tabs defaultActiveKey="info" className="mb-3">
            <Tab eventKey="info" key="info" title="Información General">
              <Row className="p-6">
                <Col sm={12}>
                  <h4 className="mb-8">Motivo de Consulta</h4>
                </Col>
                <Col sm={6}>
                  <TextLabel label="Asunto" text={consultation?.subject} loading={loading} />
                </Col>
                <Col sm={6}>
                  <TextLabel
                    label="Paciente"
                    text={`${consultation?.patient?.name} ${consultation?.patient?.lastName}`}
                    loading={loading}
                  />
                </Col>
                <Col sm={6}>
                  <TextLabel label="Diagnóstico" text={consultation?.diagnosis} loading={loading} />
                </Col>
                <Col sm={6}>
                  <TextLabel
                    label="Fecha de registro"
                    text={dayjs(consultation?.createdAt).format("DD/MM/YYYY HH:mm")}
                    loading={loading}
                  />
                </Col>
              </Row>
              <Row className="px-6">
                <Col sm={12}>
                  <h4 className="mb-8">Servicios proporcionados</h4>
                </Col>
                <Col sm={12}>
                  <ListGroup>
                    {consultation?.services?.items?.length ? (
                      consultation.services.items.map(({ id, service }) => {
                        return <ListGroup.Item key={id}>{service.name}</ListGroup.Item>;
                      })
                    ) : (
                      <ListGroup.Item variant="light">Sin servicios registrados</ListGroup.Item>
                    )}
                  </ListGroup>
                </Col>
              </Row>
            </Tab>

            <Tab eventKey="receipt" key="receipt" title="Indicaciones">
              <Row className="p-6">
                <Col sm={12}>
                  <h4 className="mb-8">Lista de Indicaciones</h4>
                </Col>
                <Col sm={12}>
                  <ListGroup>
                    {consultation?.indications?.length ? (
                      consultation.indications.map((indication, index) => (
                        <ListGroup.Item key={index} as="li">
                          {`${index + 1}. ${indication}`}
                        </ListGroup.Item>
                      ))
                    ) : (
                      <ListGroup.Item variant="light">Sin indicaciones registradas</ListGroup.Item>
                    )}
                  </ListGroup>
                </Col>
              </Row>

              <Row className="px-6 pt-4">
                <Col sm={12}>
                  <TextLabel
                    label="Notas sobre Indicaciones"
                    text={consultation?.notes || "Sin notas"}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </div>
      </div>

      <ConsultationFormModal
        open={openModal}
        onClose={hideModal}
        onUpdate={getConsultationDetails}
        consultation={consultation}
      />
    </>
  );
};
