import { useCallback, useState } from "react";
import API, { graphqlOperation } from "@aws-amplify/api";
import { Logger } from "@aws-amplify/core";
import { useNotifier } from "./useNotifier";

const logger = new Logger("useGraphQL");

export default function useGraphQL() {
  const [loading, setLoading] = useState(false);
  const notifier = useNotifier();

  const runGraphQLOperation = useCallback(
    async ({ operation, variables, notifications = {} }) => {
      const { successMsg = "", errorMsg = "" } = notifications;
      try {
        setLoading(true);
        const result = await API.graphql(graphqlOperation(operation, variables));
        successMsg && notifier.showMessage(successMsg);
        return result.data;
      } catch (error) {
        logger.error(error);
        errorMsg && notifier.showError(errorMsg);
        throw error;
      } finally {
        setLoading(false);
      }
    }, 
    [notifier]
  );

  return { loading, runGraphQLOperation };
}
