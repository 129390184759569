export const actionTypes = {
  ShowSnackbar: "[Show Snackbar] Action",
  CloseSnackbar: "[Close Snackbar] Action"
};

export const actions = {
  showSnackbar: (message, variant) => {
    return { type: actionTypes.ShowSnackbar, payload: { message, variant } };
  },
  closeSnackbar: () => {
    return { type: actionTypes.CloseSnackbar };
  },
};

const INITIAL_STATE = {
  open: false,
  message: "",
  variant: "info"
};

export const reducer = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.ShowSnackbar: {
      const { message, variant } = action.payload;
      return { ...state, open: true, message, variant };
    }
    case actionTypes.CloseSnackbar:
      return INITIAL_STATE;
    default:
      return state;
  }
}