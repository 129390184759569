export const getConsultation = /* GraphQL */ `
  query GetConsultation($id: ID!) {
    getConsultation(id: $id) {
      id
      userID
      user {
        id
        name
        lastName
        phone
        email
        createdAt
        updatedAt
      }
      patientID
      patient {
        id
        name
        lastName
        birthDate
        genre
        phone
        createdByUserId
        createdBy {
          id
          name
          lastName
          phone
          email
          createdAt
          updatedAt
        }
        createdAt
        consultations {
          nextToken
        }
        updatedAt
      }
      subject
      diagnosis
      indications
      notes
      services {
        items {
          id
          serviceID
          consultationID
          createdAt
          updatedAt
          service {
            id
            name
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;