import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Button } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import ConfirmationDialog from "../../Shared/components/ConfirmationDialog";
import { useGraphQL } from "../../Shared/hooks";
import * as mutations from "graphql/mutations";
import { IconSVG } from "../../Shared/components/IconSVG";
import { TextField } from "../../Shared/components";
import { useNotifier } from "../../Shared/hooks/useNotifier";

const initialValues = {
  name: "",
  lastname: "",
  date: "",
  gender: "",
  phone: "",
};

export default function UserFormPage() {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { runGraphQLOperation } = useGraphQL();
  const [creatingUser, setCreatingUser] = useState(false);

  const patientSchema = Yup.object().shape({
    name: Yup.string().required("Ingresa el nombre"),
    lastname: Yup.string().required("Ingresa los apellidos "),
    date: Yup.string().required("Selecciona la fecha de nacimiento"),
    gender: Yup.string().required("Selecciona el género"),
    phone: Yup.string(),
  });

  const formik = useFormik({
    initialValues,
    onSubmit: openConfirmationDialog,
    validationSchema: patientSchema,
  });

  const { showError } = useNotifier();

  async function openConfirmationDialog() {
    await formik.validateForm();
    setOpen(true);
  }

  function cancelCreatePatient() {
    history.push("/pacientes/listado");
  }

  async function createUser() {
    const { name, lastname, date, gender, phone } = formik.values;
    try {
      setCreatingUser(true);
      const { attributes: { sub: userId } } = await Auth.currentAuthenticatedUser();
      await runGraphQLOperation({
        operation: mutations.createPatient,
        variables: {
          input: {
            name,
            phone,
            genre: gender,
            lastName: lastname,
            birthDate: date,
            createdByUserId: userId
          }
        },
        notifications: {
          successMsg: "El paciente se ha registrado correctamente",
        }
      });
      setOpen(false);
      history.push("/pacientes/listado");
      setCreatingUser(false);
    } catch (error) {
      setCreatingUser(false);
      showError("Ocurrió un error durante el registro del paciente");
    }
  }

  function closeDialog() {
    setOpen(false);
  }

  return (
    <div>
      <div className="card card-custom">
        <div className="card-header">
          <div className="card-title">
            <Button variant="link" className="px-0 mr-4" onClick={cancelCreatePatient}>
              <IconSVG absolutePath="/media/svg/icons/Navigation/Close.svg" />
            </Button>
            <h3 className="card-label">Registro de paciente</h3>
          </div>
          <div className="card-toolbar">
            <button className="btn btn-primary" onClick={formik.submitForm}>
              Registrar
            </button>
          </div>
        </div>
        {/* <UserFormSteps activeStep={activeStep} /> */}
        <div className="card-body container-sm">
          <form>
            <div className="row p-6">
              <div className="col-sm-12">
                <h4 className="mb-8">Datos personales del paciente</h4>
              </div>
              <div className="col-sm-6">
                <TextField
                  name="name"
                  type="text"
                  label="Nombre(s)"
                  placeholder="Nombres"
                  touched={formik.touched.name}
                  error={formik.errors.name}
                  {...formik.getFieldProps("name")}
                />
              </div>
              <div className="col-sm-6">
                <TextField
                  name="lastname"
                  type="text"
                  label="Apellidos"
                  placeholder="Apellidos"
                  touched={formik.touched.lastname}
                  error={formik.errors.lastname}
                  {...formik.getFieldProps("lastname")}
                />
              </div>
              <div className="col-sm-6">
                <TextField
                  name="date"
                  type="date"
                  label="Fecha de nacimiento"
                  placeholder=""
                  touched={formik.touched.date}
                  error={formik.errors.date}
                  {...formik.getFieldProps("date")}
                />
              </div>
              <div className="col-sm-6">
                <TextField
                  name="gender"
                  component="select"
                  label="Género"
                  touched={formik.touched.gender}
                  error={formik.errors.gender}
                  {...formik.getFieldProps("gender")}
                >
                  <option>Selecciona el género</option>
                  <option value="MALE">Masculino</option>
                  <option value="FEMALE">Femenino</option>
                  <option value="OTHER">Otro</option>
                </TextField>
              </div>
              <div className="col-sm-6">
                <TextField
                  name="phone"
                  type="phone"
                  label="Télefono (Opcional)"
                  placeholder="Télefono de casa o móvil"
                  touched={formik.touched.phone}
                  error={formik.errors.phone}
                  {...formik.getFieldProps("phone")}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <ConfirmationDialog
        open={open}
        handleClose={closeDialog}
        handleConfirm={createUser}
        title="Confirmación"
        loading={creatingUser}
        contentBody={
          <>
            ¿Confirma que desea registrar al paciente <strong>{`${formik.values.name} ${formik.values.lastname}`}</strong> en el sistema?
          </>
        }
      />
    </div>
  );
}
