import React from "react";
import PropTypes from "prop-types";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export const IconSVG = ({ size, color, absolutePath, className }) => {
  return (
    <span className={`svg-icon svg-icon-${size} svg-icon-${color} ${className}`}>
      <SVG src={toAbsoluteUrl(absolutePath)} />
    </span>
  );
};

IconSVG.propTypes = {
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  color: PropTypes.oneOf(["primary", "secondary", "success", "danger", "warning", "info"]),
  absolutePath: PropTypes.string.isRequired,
  className: PropTypes.string,
};

IconSVG.defaultProps = {
  size: "md",
  color: "primary",
  absolutePath: "",
  className: "",
};
