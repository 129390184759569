import { useCallback, useMemo } from "react";
import useActions from "./useActions";
import * as feedback from "../../Feedback/_redux/feedbackRedux";

export function useNotifier() {
  const [showSnackbar] = useActions([feedback.actions.showSnackbar]);

  const showMessage = useCallback((message = "") => {
    showSnackbar(message, "success");
  }, [showSnackbar])
  
  const showError = useCallback((message = "") => {
    showSnackbar(message, "warning");
  }, [showSnackbar])

  return useMemo(() => ({ showMessage, showError }), [showMessage, showError]);
}