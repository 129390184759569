import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import ConsultationFormSteps from "./ConsultationFormSteps";
import { Button } from "react-bootstrap";
import { Auth, Logger } from "aws-amplify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ConsultationFormRecipeStep } from "./ConsultationFormRecipeStep";
import { ConsultationFormDiagnosisStep } from "./ConsultationFormDiagnosisStep";
import { IconSVG } from "../../Shared/components/IconSVG";
import useGraphQL from "../../Shared/hooks/useGraphQL";
import * as mutations from "graphql/mutations";
import { generateServiceConsultationMutations } from "graphql-custom/mutations";
import { useNotifier } from "../../Shared/hooks/useNotifier";
import ConfirmationDialog from "../../Shared/components/ConfirmationDialog";
import { FORM_MODE } from "../../Shared/constants/formMode";
import * as queries from "graphql/queries";

const INITIAL_FORM_VALUES = {
  title: "",
  patient: null,
  services: [],
  diagnostic: "",
  indication: "",
  description: "",
};

const FORM_STEPS = {
  DIAGNOSIS: 1,
  INDICATIONS: 2,
};

const FORM_TEXT = {
  [FORM_MODE.CREATE]: {
    title: "Registro de Consulta",
    confirmBtn: "Registrar",
    notifications: {
      successMsg: "La consulta se ha registrado correctamente",
      errorMsg: "Falló el registro de la consulta. Intente nuevamente.",
    },
  },
  [FORM_MODE.UPDATE]: {
    title: "Modificación de Consulta",
    confirmBtn: "Modificar",
    notifications: {
      successMsg: "La consulta fue actualizada correctamente.",
      errorMsg: "Ocurrió un error durante la actualización de la consulta.",
    },
  },
};

const diagnosisSchema = Yup.object().shape({
  title: Yup.string().required("Introduce el asunto"),
  patient: Yup.object()
    .nullable()
    .required("Selecciona el paciente"),
  services: Yup.array().min(1, "Selecciona por lo menos 1 servicio"),
  diagnostic: Yup.string(),
});

const indicationsSchema = Yup.object().shape({
  indication: Yup.string(),
  description: Yup.string(),
});

const logger = new Logger("ConsultationFormPage");

export const ConsultationFormPage = ({ formMode, consultation, onClose, onCreate, onUpdate }) => {
  const history = useHistory();
  const { id } = useParams();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [activeStep, setActiveStep] = useState(FORM_STEPS.DIAGNOSIS);
  const [indicationList, setIndicationList] = useState([]);
  const [patientList, setPatientList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [creatingConsultation, setCreatingConsultation] = useState(false);
  const [fetchingOptions, setFetchingOptions] = useState(false);
  const { runGraphQLOperation } = useGraphQL();
  const { showMessage, showError } = useNotifier();

  const formik = useFormik({
    initialValues: INITIAL_FORM_VALUES,
    validationSchema: FORM_STEPS.DIAGNOSIS ? diagnosisSchema : indicationsSchema,
  });

  useEffect(() => {
    fetchSelectorsOptionList();
    if (formMode === FORM_MODE.UPDATE && consultation && Object.keys(consultation).length) {
      const services = consultation.services.items.map(({ service }) => service.id);
      formik.setFieldValue("title", consultation.subject);
      formik.setFieldValue("patient", consultation.patient);
      formik.setFieldValue("services", services);
      formik.setFieldValue("diagnostic", consultation.diagnosis);
      formik.setFieldValue("description", consultation.notes);
      setIndicationList(consultation.indications);
    }
  }, []);

  const fetchSelectorsOptionList = async () => {
    setFetchingOptions(true);
    const data = await Promise.all([
      runGraphQLOperation({
        operation: queries.listPatients,
        variables: { limit: 1000 },
        notifications: { errorMsg: "Ocurrió un error durante la carga de Pacientes" },
      }),
      runGraphQLOperation({
        operation: queries.listServices,
        notifications: { errorMsg: "Ocurrió un error durante la carga de Servicios" },
      }),
    ]);
    setFetchingOptions(false);
    const [patients, services] = data;
    setPatientList(patients.listPatients.items);
    setServiceList(services.listServices.items.map(({ id, name }) => ({ value: id, label: name })));
    setPatientFieldFromParam(patients.listPatients.items, id)
  };

  const setPatientFieldFromParam = (patients = [], patientId = "") => {
    const patient = patients.find(p => p.id === patientId);
    if (patient) {
      formik.setFieldValue("patient", patient);
    }
  };

  const goNext = async () => {
    const errors = await formik.validateForm();
    formik.setTouched({ title: true, patient: true, services: true });
    if (Object.keys(errors).length) {
      return;
    }
    if (activeStep === FORM_STEPS.DIAGNOSIS) {
      setActiveStep(FORM_STEPS.INDICATIONS);
    }
    if (activeStep === FORM_STEPS.INDICATIONS) {
      setOpenConfirmation(true);
    }
  };

  const goBack = () => {
    if (activeStep === FORM_STEPS.DIAGNOSIS) {
      onClose(history);
    }
    if (activeStep === FORM_STEPS.INDICATIONS) {
      setActiveStep(FORM_STEPS.DIAGNOSIS);
    }
  };

  async function submitForm() {
    try {
      setCreatingConsultation(true);
      if (formMode === FORM_MODE.CREATE) {
        const consultationInput = await generateConsultationInput();
        const { createConsultation } = await runGraphQLOperation({
          operation: mutations.createConsultation,
          variables: { input: consultationInput },
        });
        const services = generateServiceConsultationMutations(formik.values.services, [], createConsultation.id);
        await runGraphQLOperation({
          operation: services.mutation,
          variables: services.variables,
        });
        history.push("/consultas/listado");
        onCreate();
      } else if (formMode === FORM_MODE.UPDATE) {
        const { subject, diagnosis, indications, notes, patientID } = await generateConsultationInput();
        await runGraphQLOperation({
          operation: mutations.updateConsultation,
          variables: {
            input: {
              subject,
              diagnosis,
              indications,
              notes,
              patientID,
              id: consultation.id,
            },
          },
        });
        const newServices = formik.values.services.reduce((services, id) => {
          const index = consultation.services.items.findIndex(({ service }) => service.id === id);
          if (index === -1) {
            services.push(id);
          }
          return services;
        }, []);
        const deletedServices = consultation.services.items.reduce((services, { id, service }) => {
          const index = formik.values.services.findIndex(id => id === service.id);
          if (index === -1) {
            services.push(id);
          }
          return services;
        }, []);
        if (newServices.length || deletedServices.length) {
          const { mutation, variables } = generateServiceConsultationMutations(
            newServices,
            deletedServices,
            consultation.id
          );
          await runGraphQLOperation({
            operation: mutation,
            variables: variables,
          });
        }
        onClose();
        onUpdate();
      }
      showMessage(FORM_TEXT[formMode].notifications.successMsg);
      setOpenConfirmation(false);
    } catch (error) {
      logger.error(error);
      showError(FORM_TEXT[formMode].notifications.errorMsg);
    } finally {
      setCreatingConsultation(false);
    }
  }

  const generateConsultationInput = async () => {
    const { title, patient, diagnostic, description } = formik.values;
    const {
      attributes: { sub: userId },
    } = await Auth.currentAuthenticatedUser();
    return {
      userID: userId,
      patientID: patient.id,
      subject: title,
      diagnosis: diagnostic,
      indications: indicationList,
      notes: description,
    };
  };

  function closeDialog() {
    setOpenConfirmation(false);
  }

  const addIndication = () => {
    if (!formik.values.indication.trim().length) {
      return;
    }
    setIndicationList([...indicationList, formik.values.indication]);
    formik.setFieldValue("indication", "");
  };

  const deleteIndication = index => {
    return () => {
      const newIndications = indicationList.filter((indication, i) => i !== index);
      setIndicationList(newIndications);
    };
  };

  const getIconPath = () => {
    if (activeStep === FORM_STEPS.DIAGNOSIS) {
      return "/media/svg/icons/Navigation/Close.svg";
    } else if (activeStep === FORM_STEPS.INDICATIONS) {
      return "/media/svg/icons/Navigation/Arrow-left.svg";
    }
  };

  const renderButton = () => {
    if (activeStep === FORM_STEPS.DIAGNOSIS) {
      return (
        <Button variant="link" className="px-0" onClick={goNext}>
          <IconSVG size="lg" absolutePath="/media/svg/icons/Navigation/Arrow-right.svg" />
        </Button>
      );
    } else if (activeStep === FORM_STEPS.INDICATIONS) {
      return (
        <Button variant={formMode === FORM_MODE.UPDATE ? "warning" : "primary"} onClick={goNext}>
          {FORM_TEXT[formMode].confirmBtn}
        </Button>
      );
    }
  };

  return (
    <>
      <div className="card card-custom">
        <div className="card-header">
          <div className="card-title">
            <Button variant="link" className="px-0 mr-4" onClick={goBack}>
              <IconSVG size="lg" absolutePath={getIconPath()} />
            </Button>
            <h3 className="card-label">{FORM_TEXT[formMode].title}</h3>
          </div>
          <div className="card-toolbar">{renderButton()}</div>
        </div>
        <ConsultationFormSteps activeStep={activeStep} />
        <div className="card-body container-sm pt-0">
          {activeStep === FORM_STEPS.DIAGNOSIS ? (
            <ConsultationFormDiagnosisStep
              formik={formik}
              loading={fetchingOptions}
              patients={patientList}
              services={serviceList}
            />
          ) : null}
          {activeStep === FORM_STEPS.INDICATIONS ? (
            <ConsultationFormRecipeStep
              formik={formik}
              handleAdd={addIndication}
              handleDelete={deleteIndication}
              indications={indicationList}
            />
          ) : null}
        </div>
      </div>

      <ConfirmationDialog
        open={openConfirmation}
        handleClose={closeDialog}
        handleConfirm={submitForm}
        title="Confirmación"
        loading={creatingConsultation}
        contentBody={
          <>
            ¿Confirma que desea {formMode === FORM_MODE.CREATE ? "registrar" : "actualizar"} la consulta{" "}
            <strong>{formik.values.title}</strong> del paciente{" "}
            <strong>{`${formik.values.patient?.name} ${formik.values.patient?.lastName}`}</strong> en el sistema?
          </>
        }
      />
    </>
  );
};

ConsultationFormPage.propTypes = {
  formMode: PropTypes.oneOf([FORM_MODE.CREATE, FORM_MODE.UPDATE]),
  consultation: PropTypes.object,
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  submitForm: PropTypes.func,
};

ConsultationFormPage.defaultProps = {
  formMode: FORM_MODE.CREATE,
  consultation: null,
  onClose: history => history.push("/consultas/listado"),
  onCreate: () => {},
  submitForm: () => {},
};
