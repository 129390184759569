import React from "react";
import { Dialog } from "@material-ui/core";
import Button from "react-bootstrap/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function ConfirmationDialog({ open, handleClose, title, contentBody, loading, handleConfirm }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{contentBody}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className="btn btn-secondary" disabled={loading}>
          Cancelar
        </Button>
        <Button onClick={handleConfirm} className="btn btn-primary" disabled={loading}>
          <span>Confirmar</span>
          {loading && <span className="ml-2 px-3 spinner spinner-white" />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
