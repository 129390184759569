import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { ConsultationFormPage } from "./ConsultationFormPage";
import { FORM_MODE } from "../../Shared/constants/formMode";

export const ConsultationFormModal = ({ open, onClose, onCreate, onUpdate, consultation }) => {
  return (
    <Modal show={open} size="lg">
      <ConsultationFormPage
        formMode={FORM_MODE.UPDATE}
        consultation={consultation}
        onClose={onClose}
        onCreate={onCreate}
        onUpdate={onUpdate}
      />
    </Modal>
  );
};

ConsultationFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  consultation: PropTypes.object,
};

ConsultationFormModal.defaultProps = {
  open: false,
  onClose: () => {},
  onCreate: () => {},
  onUpdate: () => {},
  consultation: {},
};
