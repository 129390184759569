import React from "react";
import PropTypes from "prop-types";
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress, TextField as MUITextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

export const AutocompleteField = ({
  options,
  onChange,
  onBlur,
  value,
  getOptionLabel,
  getOptionSelected,
  name,
  label,
  placeholder,
  noOptionsText,
  touched,
  error,
  disabled,
  loading,
}) => {
  const classes = useStyles();

  return (
    <Autocomplete
      fullWidth
      options={options}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      noOptionsText={noOptionsText}
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      disabled={disabled}
      popupIcon={
        loading ? (
          <CircularProgress color="inherit" size={19} className="mr-3" />
        ) : (
          <ArrowDropDownIcon color="inherit" />
        )
      }
      renderInput={params => {
        return (
          <>
            <MUITextField
              {...params}
              name={name}
              label={label}
              placeholder={placeholder}
              touched={touched}
              error={touched && !!error}
              helperText={touched && error}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                classes: {
                  root: classes.inputRoot,
                  input: classes.input,
                  focused: classes.inputFocused,
                  error: classes.inputError,
                },
                // endAdornment: loading ? <CircularProgress color="inherit" size={20} /> : null,
              }}
              InputLabelProps={{
                ...params.InputLabelProps,
                shrink: true,
                classes: {
                  root: classes.formLabel,
                  focused: classes.formLabelFocused,
                },
              }}
              FormHelperTextProps={{
                ...params.FormHelperTextProps,
                classes: {
                  root: classes.helperText,
                },
              }}
            />
          </>
        );
      }}
    />
  );
};

AutocompleteField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  getOptionSelected: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  noOptionsText: PropTypes.string,
  touched: PropTypes.bool,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

AutocompleteField.defaultProps = {
  options: [],
  onChange: () => {},
  onBlur: () => {},
  value: null,
  getOptionLabel: () => {},
  getOptionSelected: () => {},
  name: "",
  label: "",
  placeholder: "Selecciona o busca la opción deseada",
  noOptionsText: "No se encontraron opciones",
  touched: false,
  error: "",
  disabled: false,
  loading: false,
};

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: "22px",
  },
  inputRoot: {
    "label + &": {
      marginTop: "0px",
    },
    border: "1px solid #E4E6EF",
    borderRadius: "0.42rem",
    marginBottom: "22px",
  },
  inputFocused: {
    borderColor: "#de2c40",
  },
  inputError: {
    borderColor: "#F64E60",
    marginBottom: "0px",
  },
  input: {
    padding: "11px 14px !important",
    marginTop: "0px",
    "&::placeholder": {
      color: "#B5B5C3",
      opacity: 1,
    },
  },
  formLabel: {
    color: "#3F4254 !important",
    fontWeight: "500 !important",
    fontSize: "1.3rem !important",
    lineHeight: "1.6rem",
    position: "relative",
    marginBottom: "5px",
    "&$formLabelFocused": {
      color: "#3F4254",
    },
  },
  formLabelFocused: {},
  helperText: {
    color: "#F64E60 !important",
    fontSize: "0.9rem",
    marginBottom: "22px",
  },
}));
