export function generateServiceConsultationMutations(added = [], deleted = [], consultationId) {
  const createMutations = {
    inputs: "",
    variables: {},
    operations: "",
  };

  const deleteMutations = {
    inputs: "",
    variables: {},
    operations: "",
  };

  added.forEach((serviceId, index) => {
    createMutations.inputs += `$createInput${index}: CreateServiceConsultationInput!, `;
    createMutations.variables[`createInput${index}`] = { serviceID: serviceId, consultationID: consultationId };
    createMutations.operations += `
      add${index}: createServiceConsultation(input: $createInput${index}) {
        id
        serviceID
        consultationID
      },
    `;
  });

  deleted.forEach((serviceConsultationId, index) => {
    deleteMutations.inputs += `$deleteInput${index}: DeleteServiceConsultationInput!, `;
    deleteMutations.variables[`deleteInput${index}`] = { id: serviceConsultationId };
    deleteMutations.operations += `
      del${index}: deleteServiceConsultation(input: $deleteInput${index}) {
        id
        serviceID
        consultationID
      },
    `;
  });

  let mutation = `mutation MutateServiceConsultationRelations(${createMutations.inputs + deleteMutations.inputs}) {
    ${createMutations.operations + deleteMutations.operations}
  }`;

  return {
    mutation,
    variables: { ...createMutations.variables, ...deleteMutations.variables },
  };
}
