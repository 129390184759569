import React from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import { NoRecordsFoundMessage } from "_metronic/_helpers";

export default function Table({ columns, data, loading }) {
  return (
    <BootstrapTable
      bootstrap4
      headerWrapperClasses="bg-light rounded"
      wrapperClasses="table-responsive"
      bordered={false}
      classes="table table-head-custom table-vertical-center overflow-hidden"
      columns={columns}
      keyField="id"
      data={data}
      loading={loading}
      noDataIndication={NoRecordsFoundMessage}
      pagination={paginationFactory({ sizePerPageList, sizePerPage })}
      overlay={overlayFactory({
        spinner: true,
        styles,
      })}
    />
  );
}

Table.propTypes = {
  paginationOptions: PropTypes.object,
  loading: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  onTableChange: PropTypes.func,
};

Table.defaultProps = {
  paginationOptions: {
    page: 1,
    sizePerPage: 5,
  },
  loading: false,
  columns: [],
  data: [],
  onTableChange: () => {},
};

export const sizePerPageList = [
  { text: "5", value: 5 },
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "50", value: 50 },
];

const sizePerPage = 5;

const styles = {
  overlay: (base) => ({ ...base, background: "rgba(255, 255, 255, 0.5)" }),
  spinner: (base) => ({
    ...base,
    "& svg": {
      ...base["& svg"],
      "& circle": {
        ...base["& svg"]["& circle"],
        stroke: "rgba(255, 0, 0, 1)",
      },
    },
  }),
};