import { Auth, Hub, Logger } from "aws-amplify";
import { useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { AuthEvent } from "../constants/authEvent";
import * as authRedux from "../../Auth/_redux/authRedux";
import useActions from "./useActions";

const logger = new Logger("useAuthSetup");

export function useAuthSetup() {
  const { authState, authToken } = useSelector(({ auth }) => ({ authState: auth.authState, authToken: auth.authToken }));
  const [resetUser] = useActions([authRedux.actions.resetUser]);

  useEffect(() => {
    const authListener = Hub.listen("auth", handleAuthEventChange);
    if (authState === AuthEvent.SIGN_IN) {
      validateAuthSession();
    }
    return () => {
      authListener();
    }
  }, []);

  function handleAuthEventChange({ payload }) {
    switch(payload.event) {
      case AuthEvent.SIGN_OUT: {
        resetUser();
        break;
      }
      case AuthEvent.TOKEN_REFRESH_FAILURE: {
        Auth.signOut();
        break;
      }
      default: {
        logger.warn("handleAuthEventChange: Auth event not proccesed: ", payload)
      }
    }
  }

  const validateAuthSession = async () => {
    try {
      const authUser = await Auth.currentAuthenticatedUser();
      if (!authUser) {
        throw new Error("El usuario no está autenticado.");
      }
    } catch (error) {
      logger.error(error);
      resetUser();
    }
  };

  return useMemo(() => ({
    isAuthorized: authState === AuthEvent.SIGN_IN && Boolean(authToken)
  }), [authState, authToken]);
}
