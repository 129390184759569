/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const generatePrescription = /* GraphQL */ `
  mutation GeneratePrescription($input: GeneratePrescriptionInput) {
    generatePrescription(input: $input) {
      statusCode
      fileUrl
      error
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      lastName
      phone
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      lastName
      phone
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      lastName
      phone
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPatient = /* GraphQL */ `
  mutation CreatePatient(
    $input: CreatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    createPatient(input: $input, condition: $condition) {
      id
      name
      lastName
      birthDate
      genre
      phone
      createdByUserId
      createdBy {
        id
        name
        lastName
        phone
        email
        createdAt
        updatedAt
        __typename
      }
      createdAt
      consultations {
        items {
          id
          userID
          patientID
          subject
          diagnosis
          indications
          notes
          createdAt
          updatedAt
        }
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const updatePatient = /* GraphQL */ `
  mutation UpdatePatient(
    $input: UpdatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    updatePatient(input: $input, condition: $condition) {
      id
      name
      lastName
      birthDate
      genre
      phone
      createdByUserId
      createdBy {
        id
        name
        lastName
        phone
        email
        createdAt
        updatedAt
        __typename
      }
      createdAt
      consultations {
        items {
          id
          userID
          patientID
          subject
          diagnosis
          indications
          notes
          createdAt
          updatedAt
        }
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const deletePatient = /* GraphQL */ `
  mutation DeletePatient(
    $input: DeletePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    deletePatient(input: $input, condition: $condition) {
      id
      name
      lastName
      birthDate
      genre
      phone
      createdByUserId
      createdBy {
        id
        name
        lastName
        phone
        email
        createdAt
        updatedAt
        __typename
      }
      createdAt
      consultations {
        items {
          id
          userID
          patientID
          subject
          diagnosis
          indications
          notes
          createdAt
          updatedAt
        }
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const createAttachment = /* GraphQL */ `
  mutation CreateAttachment(
    $input: CreateAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    createAttachment(input: $input, condition: $condition) {
      id
      bucket
      region
      key
      consultationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAttachment = /* GraphQL */ `
  mutation UpdateAttachment(
    $input: UpdateAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    updateAttachment(input: $input, condition: $condition) {
      id
      bucket
      region
      key
      consultationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAttachment = /* GraphQL */ `
  mutation DeleteAttachment(
    $input: DeleteAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    deleteAttachment(input: $input, condition: $condition) {
      id
      bucket
      region
      key
      consultationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAilment = /* GraphQL */ `
  mutation CreateAilment(
    $input: CreateAilmentInput!
    $condition: ModelAilmentConditionInput
  ) {
    createAilment(input: $input, condition: $condition) {
      id
      name
      type
      users {
        items {
          id
          userID
          ailmentID
          description
          createdAt
          updatedAt
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAilment = /* GraphQL */ `
  mutation UpdateAilment(
    $input: UpdateAilmentInput!
    $condition: ModelAilmentConditionInput
  ) {
    updateAilment(input: $input, condition: $condition) {
      id
      name
      type
      users {
        items {
          id
          userID
          ailmentID
          description
          createdAt
          updatedAt
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAilment = /* GraphQL */ `
  mutation DeleteAilment(
    $input: DeleteAilmentInput!
    $condition: ModelAilmentConditionInput
  ) {
    deleteAilment(input: $input, condition: $condition) {
      id
      name
      type
      users {
        items {
          id
          userID
          ailmentID
          description
          createdAt
          updatedAt
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserAilment = /* GraphQL */ `
  mutation CreateUserAilment(
    $input: CreateUserAilmentInput!
    $condition: ModelUserAilmentConditionInput
  ) {
    createUserAilment(input: $input, condition: $condition) {
      id
      userID
      ailmentID
      user {
        id
        name
        lastName
        phone
        email
        createdAt
        updatedAt
        __typename
      }
      ailment {
        id
        name
        type
        users {
          nextToken
        }
        createdAt
        updatedAt
        __typename
      }
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserAilment = /* GraphQL */ `
  mutation UpdateUserAilment(
    $input: UpdateUserAilmentInput!
    $condition: ModelUserAilmentConditionInput
  ) {
    updateUserAilment(input: $input, condition: $condition) {
      id
      userID
      ailmentID
      user {
        id
        name
        lastName
        phone
        email
        createdAt
        updatedAt
        __typename
      }
      ailment {
        id
        name
        type
        createdAt
        updatedAt
        __typename
      }
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserAilment = /* GraphQL */ `
  mutation DeleteUserAilment(
    $input: DeleteUserAilmentInput!
    $condition: ModelUserAilmentConditionInput
  ) {
    deleteUserAilment(input: $input, condition: $condition) {
      id
      userID
      ailmentID
      user {
        id
        name
        lastName
        phone
        email
        createdAt
        updatedAt
        __typename
      }
      ailment {
        id
        name
        type
        createdAt
        updatedAt
        __typename
      }
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createConsultation = /* GraphQL */ `
  mutation CreateConsultation(
    $input: CreateConsultationInput!
    $condition: ModelConsultationConditionInput
  ) {
    createConsultation(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        name
        lastName
        phone
        email
        createdAt
        updatedAt
        __typename
      }
      patientID
      patient {
        id
        name
        lastName
        birthDate
        genre
        phone
        createdByUserId
        createdBy {
          id
          name
          lastName
          phone
          email
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        __typename
      }
      subject
      diagnosis
      indications
      notes
      services {
        items {
          id
          serviceID
          consultationID
          createdAt
          updatedAt
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateConsultation = /* GraphQL */ `
  mutation UpdateConsultation(
    $input: UpdateConsultationInput!
    $condition: ModelConsultationConditionInput
  ) {
    updateConsultation(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        name
        lastName
        phone
        email
        createdAt
        updatedAt
        __typename
      }
      patientID
      patient {
        id
        name
        lastName
        birthDate
        genre
        phone
        createdByUserId
        createdBy {
          id
          name
          lastName
          phone
          email
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        __typename
      }
      subject
      diagnosis
      indications
      notes
      services {
        items {
          id
          serviceID
          consultationID
          createdAt
          updatedAt
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteConsultation = /* GraphQL */ `
  mutation DeleteConsultation(
    $input: DeleteConsultationInput!
    $condition: ModelConsultationConditionInput
  ) {
    deleteConsultation(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        name
        lastName
        phone
        email
        createdAt
        updatedAt
        __typename
      }
      patientID
      patient {
        id
        name
        lastName
        birthDate
        genre
        phone
        createdByUserId
        createdBy {
          id
          name
          lastName
          phone
          email
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        __typename
      }
      subject
      diagnosis
      indications
      notes
      services {
        items {
          id
          serviceID
          consultationID
          createdAt
          updatedAt
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createService = /* GraphQL */ `
  mutation CreateService(
    $input: CreateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    createService(input: $input, condition: $condition) {
      id
      name
      description
      consultations {
        items {
          id
          serviceID
          consultationID
          createdAt
          updatedAt
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateService = /* GraphQL */ `
  mutation UpdateService(
    $input: UpdateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    updateService(input: $input, condition: $condition) {
      id
      name
      description
      consultations {
        items {
          id
          serviceID
          consultationID
          createdAt
          updatedAt
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteService = /* GraphQL */ `
  mutation DeleteService(
    $input: DeleteServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    deleteService(input: $input, condition: $condition) {
      id
      name
      description
      consultations {
        items {
          id
          serviceID
          consultationID
          createdAt
          updatedAt
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createServiceConsultation = /* GraphQL */ `
  mutation CreateServiceConsultation(
    $input: CreateServiceConsultationInput!
    $condition: ModelServiceConsultationConditionInput
  ) {
    createServiceConsultation(input: $input, condition: $condition) {
      id
      serviceID
      consultationID
      service {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      consultation {
        id
        userID
        user {
          id
          name
          lastName
          phone
          email
          createdAt
          updatedAt
        }
        patientID
        patient {
          id
          name
          lastName
          birthDate
          genre
          phone
          createdByUserId
          createdAt
          updatedAt
        }
        subject
        diagnosis
        indications
        notes
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateServiceConsultation = /* GraphQL */ `
  mutation UpdateServiceConsultation(
    $input: UpdateServiceConsultationInput!
    $condition: ModelServiceConsultationConditionInput
  ) {
    updateServiceConsultation(input: $input, condition: $condition) {
      id
      serviceID
      consultationID
      service {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      consultation {
        id
        userID
        user {
          id
          name
          lastName
          phone
          email
          createdAt
          updatedAt
        }
        patientID
        patient {
          id
          name
          lastName
          birthDate
          genre
          phone
          createdByUserId
          createdAt
          updatedAt
        }
        subject
        diagnosis
        indications
        notes
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteServiceConsultation = /* GraphQL */ `
  mutation DeleteServiceConsultation(
    $input: DeleteServiceConsultationInput!
    $condition: ModelServiceConsultationConditionInput
  ) {
    deleteServiceConsultation(input: $input, condition: $condition) {
      id
      serviceID
      consultationID
      service {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      consultation {
        id
        userID
        user {
          id
          name
          lastName
          phone
          email
          createdAt
          updatedAt
        }
        patientID
        patient {
          id
          name
          lastName
          birthDate
          genre
          phone
          createdByUserId
          createdAt
          updatedAt
        }
        subject
        diagnosis
        indications
        notes
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument(
    $input: CreateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    createDocument(input: $input, condition: $condition) {
      id
      type
      prescription {
        id
        comments
        createdAt
        updatedAt
        __typename
      }
      template {
        id
        name
        description
        attachment {
          id
          bucket
          region
          key
          consultationID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument(
    $input: UpdateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    updateDocument(input: $input, condition: $condition) {
      id
      type
      prescription {
        id
        comments
        createdAt
        updatedAt
        __typename
      }
      template {
        id
        name
        description
        attachment {
          id
          bucket
          region
          key
          consultationID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument(
    $input: DeleteDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    deleteDocument(input: $input, condition: $condition) {
      id
      type
      prescription {
        id
        comments
        createdAt
        updatedAt
        __typename
      }
      template {
        id
        name
        description
        attachment {
          id
          bucket
          region
          key
          consultationID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTemplate = /* GraphQL */ `
  mutation CreateTemplate(
    $input: CreateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    createTemplate(input: $input, condition: $condition) {
      id
      name
      description
      attachment {
        id
        bucket
        region
        key
        consultationID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTemplate = /* GraphQL */ `
  mutation UpdateTemplate(
    $input: UpdateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    updateTemplate(input: $input, condition: $condition) {
      id
      name
      description
      attachment {
        id
        bucket
        region
        key
        consultationID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTemplate = /* GraphQL */ `
  mutation DeleteTemplate(
    $input: DeleteTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    deleteTemplate(input: $input, condition: $condition) {
      id
      name
      description
      attachment {
        id
        bucket
        region
        key
        consultationID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPrescription = /* GraphQL */ `
  mutation CreatePrescription(
    $input: CreatePrescriptionInput!
    $condition: ModelPrescriptionConditionInput
  ) {
    createPrescription(input: $input, condition: $condition) {
      id
      comments
      drugs {
        items {
          id
          drugID
          prescriptionID
          instruction
          createdAt
          updatedAt
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePrescription = /* GraphQL */ `
  mutation UpdatePrescription(
    $input: UpdatePrescriptionInput!
    $condition: ModelPrescriptionConditionInput
  ) {
    updatePrescription(input: $input, condition: $condition) {
      id
      comments
      drugs {
        items {
          id
          drugID
          prescriptionID
          instruction
          createdAt
          updatedAt
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePrescription = /* GraphQL */ `
  mutation DeletePrescription(
    $input: DeletePrescriptionInput!
    $condition: ModelPrescriptionConditionInput
  ) {
    deletePrescription(input: $input, condition: $condition) {
      id
      comments
      drugs {
        items {
          id
          drugID
          prescriptionID
          instruction
          createdAt
          updatedAt
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDrug = /* GraphQL */ `
  mutation CreateDrug(
    $input: CreateDrugInput!
    $condition: ModelDrugConditionInput
  ) {
    createDrug(input: $input, condition: $condition) {
      id
      name
      activeSubstance
      dose
      unit
      prescriptions {
        items {
          id
          drugID
          prescriptionID
          instruction
          createdAt
          updatedAt
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDrug = /* GraphQL */ `
  mutation UpdateDrug(
    $input: UpdateDrugInput!
    $condition: ModelDrugConditionInput
  ) {
    updateDrug(input: $input, condition: $condition) {
      id
      name
      activeSubstance
      dose
      unit
      prescriptions {
        items {
          id
          drugID
          prescriptionID
          instruction
          createdAt
          updatedAt
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDrug = /* GraphQL */ `
  mutation DeleteDrug(
    $input: DeleteDrugInput!
    $condition: ModelDrugConditionInput
  ) {
    deleteDrug(input: $input, condition: $condition) {
      id
      name
      activeSubstance
      dose
      unit
      prescriptions {
        items {
          id
          drugID
          prescriptionID
          instruction
          createdAt
          updatedAt
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDrugPrescription = /* GraphQL */ `
  mutation CreateDrugPrescription(
    $input: CreateDrugPrescriptionInput!
    $condition: ModelDrugPrescriptionConditionInput
  ) {
    createDrugPrescription(input: $input, condition: $condition) {
      id
      drugID
      prescriptionID
      drug {
        id
        name
        activeSubstance
        dose
        unit
        createdAt
        updatedAt
        __typename
      }
      prescription {
        id
        comments
        createdAt
        updatedAt
        __typename
      }
      instruction
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDrugPrescription = /* GraphQL */ `
  mutation UpdateDrugPrescription(
    $input: UpdateDrugPrescriptionInput!
    $condition: ModelDrugPrescriptionConditionInput
  ) {
    updateDrugPrescription(input: $input, condition: $condition) {
      id
      drugID
      prescriptionID
      drug {
        id
        name
        activeSubstance
        dose
        unit
        createdAt
        updatedAt
        __typename
      }
      prescription {
        id
        comments
        createdAt
        updatedAt
        __typename
      }
      instruction
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDrugPrescription = /* GraphQL */ `
  mutation DeleteDrugPrescription(
    $input: DeleteDrugPrescriptionInput!
    $condition: ModelDrugPrescriptionConditionInput
  ) {
    deleteDrugPrescription(input: $input, condition: $condition) {
      id
      drugID
      prescriptionID
      drug {
        id
        name
        activeSubstance
        dose
        unit
        createdAt
        updatedAt
        __typename
      }
      prescription {
        id
        comments
        createdAt
        updatedAt
        __typename
      }
      instruction
      createdAt
      updatedAt
      __typename
    }
  }
`;
