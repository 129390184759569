import React from "react";
import { Stepper, Step, StepLabel, Typography, Box, useTheme } from "@material-ui/core";
import { Grid } from "@material-ui/core";

const ConsultationFormSteps = ({ activeStep }) => {
  const theme = useTheme();

  function renderStepIcon() {
    return <Typography color={activeStep === 1 ? "error" : "textPrimary"}>1</Typography>;
  }

  function renderStepIconTwo() {
    return <Typography color={activeStep === 2 ? "error" : "textPrimary"}> 2 </Typography>;
  }

  return (
    <div>
      <Stepper activeStep={0} connector={null}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Step>
              <StepLabel StepIconComponent={renderStepIcon}>
                <Typography color={activeStep === 1 ? "error" : "textPrimary"}>Información General</Typography>
              </StepLabel>
              <Box
                borderBottom={2}
                borderRadius={1}
                style={{
                  borderColor: activeStep === 1 ? theme.palette.error.main : theme.palette.text.disabled,
                }}
              />
            </Step>
          </Grid>
          <Grid item xs={6}>
            <Step>
              <StepLabel StepIconComponent={renderStepIconTwo}>
                <Typography color={activeStep === 2 ? "error" : "textPrimary"}>Indicaciones</Typography>
              </StepLabel>
              <Box
                borderBottom={2}
                borderRadius={1}
                style={{
                  borderColor: activeStep === 2 ? theme.palette.error.main : theme.palette.text.disabled,
                }}
              />
            </Step>
          </Grid>
        </Grid>
      </Stepper>
    </div>
  );
};

export default ConsultationFormSteps;
