import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  TextField as MUITextField,
  MenuItem,
  Checkbox,
  ListItemText,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

export const Selector = ({
  options,
  onChange,
  onBlur,
  value,
  name,
  label,
  placeholder,
  touched,
  error,
  multiple,
  disabled,
  loading,
}) => {
  const classes = useStyles();

  const optionsMap = useMemo(() => {
    if (options.length) {
      return options.reduce((optMap, option) => {
        optMap[option.value] = option.label;
        return optMap;
      }, {});
    } else {
      return null;
    }
  }, [options]);

  const renderOptions = () => {
    if (multiple) {
      return options.map(({ label, value: optValue }) => (
        <MenuItem key={optValue} value={optValue}>
          <Checkbox checked={value.indexOf(optValue) > -1} color="primary" />
          <ListItemText primary={label} />
        </MenuItem>
      ));
    } else {
      return options.map(({ label, value: optValue }) => (
        <MenuItem key={optValue} value={optValue}>
          {label}
        </MenuItem>
      ));
    }
  };

  const renderValue = selected => {
    if (!selected?.length || !optionsMap) {
      return placeholder;
    }
    if (multiple) {
      const selectedLabels = selected.reduce((labels, value, index) => {
        if (index === selected.length - 1) {
          labels += `${optionsMap[value]}`;
        } else {
          labels += `${optionsMap[value]}, `;
        }
        return labels;
      }, "");
      return selectedLabels;
    } else {
      return optionsMap[selected];
    }
  };

  return (
    <MUITextField
      select
      fullWidth
      name={name}
      label={label}
      touched={touched}
      error={touched && !!error}
      helperText={touched && error}
      SelectProps={{
        multiple,
        onChange,
        onBlur,
        value,
        renderValue,
        disabled,
        displayEmpty: true,
        IconComponent: () => {
          return loading ? (
            <CircularProgress color="inherit" size={20} className="mr-3" />
          ) : (
            <ArrowDropDownIcon color="inherit" />
          );
        },
      }}
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.inputRoot,
          input: value.length ? classes.inputSelected : classes.inputEmpty,
          focused: classes.inputFocused,
          error: classes.inputError,
        },
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: classes.formLabel,
          focused: classes.formLabelFocused,
        },
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.helperText,
        },
      }}
    >
      {renderOptions()}
    </MUITextField>
  );
};

Selector.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  touched: PropTypes.bool,
  error: PropTypes.string,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

Selector.defaultProps = {
  options: [],
  onChange: () => {},
  onBlur: () => {},
  value: [],
  name: "",
  label: "",
  placeholder: "Selecciona la opción deseada",
  touched: false,
  error: "",
  multiple: false,
  disabled: false,
  loading: false,
};

const useStyles = makeStyles(theme => ({
  inputRoot: {
    "label + &": {
      marginTop: "0px",
    },
    border: "1px solid #E4E6EF",
    borderRadius: "0.42rem",
    marginBottom: "22px",
  },
  inputFocused: {
    borderColor: "#de2c40",
  },
  inputError: {
    borderColor: "#F64E60",
    marginBottom: "0px",
  },
  inputEmpty: {
    padding: "11px 14px !important",
    marginTop: "0px",
    color: "#B5B5C3",
  },
  inputSelected: {
    padding: "11px 14px !important",
    marginTop: "0px",
    color: "#3F4254",
  },
  formLabel: {
    color: "#3F4254 !important",
    fontWeight: "500 !important",
    fontSize: "1.3rem !important",
    lineHeight: "1.6rem",
    position: "relative",
    marginBottom: "5px",
    "&$formLabelFocused": {
      color: "#3F4254",
    },
  },
  formLabelFocused: {},
  helperText: {
    color: "#F64E60 !important",
    fontSize: "0.9rem",
    marginBottom: "22px",
  },
}));
