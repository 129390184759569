import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { TextField } from "../../Shared/components";
import { AutocompleteField } from "../../Shared/components/AutocompleteField";
import { Selector } from "../../Shared/components/Selector";

export const ConsultationFormDiagnosisStep = ({ formik, loading, patients, services }) => {
  return (
    <div className="card-body">
      <div className="card-title">
        <h3 className="card-label">Información del motivo de consulta</h3>
      </div>
      <Row>
        <Col sm={12}>
          <TextField
            name="title"
            label="Asunto"
            placeholder="Asunto de la consulta"
            touched={formik.touched.title}
            error={formik.errors.title}
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Col>
        <Col sm={6}>
          <AutocompleteField
            name="patient"
            label="Paciente"
            placeholder="Nombre o apellidos del paciente"
            error={formik.errors.patient}
            touched={formik.touched.patient}
            value={formik.values.patient}
            options={patients}
            onChange={(event, value) => formik.setFieldValue("patient", value || null)}
            onBlur={formik.handleBlur}
            loading={loading}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={option => `${option.name} ${option.lastName}`}
            noOptionsText="No se encontraron opciones"
          />
        </Col>
        <Col sm={6}>
          <Selector
            multiple
            name="services"
            component="select"
            label="Servicio"
            placeholder="Selecciona los servicios"
            touched={formik.touched.services}
            error={formik.errors.services}
            value={formik.values.services}
            options={services}
            loading={loading}
            onBlur={formik.handleBlur}
            onChange={event => {
              formik.setFieldValue("services", event?.target?.value || []);
            }}
          />
        </Col>
        <Col sm={12}>
          <TextField
            name="diagnostic"
            component="textarea"
            label="Descripción del diagnóstico"
            placeholder="Describa el diagnóstico del paciente"
            touched={formik.touched.diagnostic}
            error={formik.errors.diagnostic}
            value={formik.values.diagnostic}
            onChange={formik.handleChange}
            rows={4}
          />
        </Col>
      </Row>
    </div>
  );
};

ConsultationFormDiagnosisStep.propTypes = {
  formik: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  patients: PropTypes.array,
  services: PropTypes.array,
};

ConsultationFormDiagnosisStep.defaultProps = {
  formik: {},
  loading: false,
  patients: [],
  services: [],
};
