import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";
import * as auth from "../_redux/authRedux";
import { useActions } from "../../Shared/hooks";
import { TextField } from "../../Shared/components";

const initialValues = {
  email: "",
  password: "",
};

function Login() {
  const { loading, error } = useSelector(({ auth }) => auth);
  const [login] = useActions([auth.actions.login]);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .required("Campo requerido")
      .email("Formato incorrecto de correo"),
    password: Yup.string()
      .required("Campo requerido")
      .min(8, "La contraseña debe tener 8 caracteres por lo menos")
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm, "Formato incorrecto de contraseña"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: ({ email, password }) => {
      login(email, password);
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Ingrese su correo electrónico y contraseña
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {error.length ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{error}</div>
          </div>
        ) : null}

        <TextField 
          name="email"
          type="email"
          label="Correo"
          placeholder="Correo electrónico"
          touched={formik.touched.email}
          error={formik.errors.email}
          {...formik.getFieldProps("email")}
        />
        <TextField 
          name="password"
          type="password"
          label="Contraseña"
          placeholder="Contraseña"
          touched={formik.touched.password}
          error={formik.errors.password}
          {...formik.getFieldProps("password")}
        />

        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark text-hover-primary font-weight-bold my-3 mr-2"
            id="kt_login_forgot"
          >
            <u>
              <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
            </u>
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={loading}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Ingresar</span>
            {loading && <span className="ml-3 spinner spinner-white" />}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default Login;
