/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const [expanded, setExpanded] = useState(false);

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const toggle = () => {
    setExpanded(!expanded);
  };

  const onSelect = (eventKey) => {
    if (eventKey >= 1) {
      toggle();
    }
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}
          // collapseOnSelect
          // expand="md"
          onToggle={toggle}
          expanded={expanded}
          onSelect={onSelect}

      >
        {/*begin::1 Level*/}
        {/* TODO: Evaluar si el modulo de Panel es necesario y habilitarlo en caso de ser necesario  */}
        {/* <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link py-7" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-4-blocks.svg")} />
            </span>
            <span className="menu-text">Panel</span>
            <span className="svg-icon menu-subicon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Navigation/Angle-right.svg"
                )}
              />
            </span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/pacientes", false)}`}
          aria-haspopup="true"
        >
          <NavLink eventKey={1} className="menu-link py-7" to="/pacientes/listado">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
            </span>
            <span className="menu-text">Pacientes</span>
            <span className="svg-icon menu-subicon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Navigation/Angle-right.svg"
                )}
              />
            </span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* eCommerce */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/consultas",
            false
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink eventKey={2} className="menu-link py-7 menu-toggle" to="/consultas/listado">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")} />
            </span>
            <span className="menu-text">Consultas</span>
            <span className="svg-icon menu-subicon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Navigation/Angle-right.svg"
                )}
              />
            </span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {/* TODO: Evaluar si el modulo Reportes es necesario y habilitarlo en caso de ser necesario  */}
        {/* <li
          className={`menu-item ${getMenuItemActive("/user-profile", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link py-7" to="/reportes">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Media/Equalizer.svg"
                )}
              />
            </span>
            <span className="menu-text">Reportes</span>
            <span className="svg-icon menu-subicon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Navigation/Angle-right.svg"
                )}
              />
            </span>
          </NavLink>
        </li> */}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
