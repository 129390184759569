import axios from "axios";
import { Auth } from 'aws-amplify';

export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = "api/me";

export function signIn(email, password) {
  return Auth.signIn(email, password);
}

export function signOut() {
  return Auth.signOut();
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function requestPasswordChange(email) {
  return Auth.forgotPassword(email);
}

export function changePassword(email, code, password) {
  return Auth.forgotPasswordSubmit(email, code, password);
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
