import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as auth from "../_redux/authRedux";
import { TextField } from "../../Shared/components";
import { useActions } from "../../Shared/hooks";
import { FormattedMessage } from "react-intl";

const INITIAL_FORM_VALUES = {
  email: "",
  code: "",
  password: "",
  passwordConfirmation: "",
};

const FORM_STEPS = {
  EMAIL_VALIDATION: "EMAIL_VALIDATION",
  PASSWORD_CONFIG: "PASSWORD_CONFIG",
};

const STEP_TEXT = {
  [FORM_STEPS.EMAIL_VALIDATION]: {
    subtitle: "Introduce tu correo para recuperar tu contraseña",
    confirmBtn: "Continuar",
    cancelBtn: "Cancelar",
  },
  [FORM_STEPS.PASSWORD_CONFIG]: {
    subtitle: "Ingresa el código enviado a tu correo, la nueva contraseña y la confirmación",
    confirmBtn: "Completar",
    cancelBtn: "Regresar",
  },
};

function ForgotPassword() {
  const history = useHistory();
  const { loading, error, validEmail, passwordChanged } = useSelector(({ auth }) => auth);
  const [recoverPassword, requestPasswordChange, resetUser] = useActions([
    auth.actions.recoverPassword,
    auth.actions.requestPasswordChange,
    auth.actions.resetUser
  ]);
  const [currentStep, setCurrentStep] = useState(FORM_STEPS.EMAIL_VALIDATION);

  const emailValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Formato de correo incorrecto")
      .required("Campo requerido")
  });

  const passwordConfigSchema = Yup.object().shape({
    code: Yup.string()
      .required("Campo requerido")
      .min(4, "El código debe tener 4 dígitos por lo menos")
      .max(999999, "El còdigo NO puede tener más de 6 dìgitos"),
    password: Yup.string()
      .required("Campo requerido")
      .min(8, "La contraseña debe tener 8 caracteres por lo menos")
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm, "Formato incorrecto de contraseña"),
    passwordConfirmation: Yup.string()
      .required("Campo requerido")
      .test("passwords-match", "La confirmación debe coincidir con la contraseña nueva", function(value) {
        return this.parent.password === value
      })
  });

  const formik = useFormik({
    initialValues: INITIAL_FORM_VALUES,
    validationSchema: currentStep === FORM_STEPS.EMAIL_VALIDATION ? emailValidationSchema : passwordConfigSchema,
    onSubmit: values => {
      if (currentStep === FORM_STEPS.EMAIL_VALIDATION) {
        recoverPassword(values.email);
      } else if (currentStep === FORM_STEPS.PASSWORD_CONFIG) {
        const { email, code, password } = values;
        requestPasswordChange(email, code, password);
      }
    },
  });

  useEffect(() => {
    resetUser();
  }, [resetUser]);

  useEffect(() => {
    if (validEmail) {
      setCurrentStep(FORM_STEPS.PASSWORD_CONFIG);
      const { email } = formik.values;
      formik.resetForm({ values: { email } });
      resetUser();
    }
    
    return () => {
      resetUser();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validEmail, resetUser]);

  const cancelPasswordRecovery = () => {
    if (currentStep === FORM_STEPS.EMAIL_VALIDATION) {
      history.push("/auth");
    } else if (currentStep === FORM_STEPS.PASSWORD_CONFIG) {
      setCurrentStep(FORM_STEPS.EMAIL_VALIDATION);
    }
  };

  return (
    <>
      {passwordChanged && <Redirect to="/auth" />}
      {!passwordChanged && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Recuperación de contraseña</h3>
            <div className="text-muted font-weight-bold">
              {STEP_TEXT[currentStep].subtitle}
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {error.length ? (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {error}
                </div>
              </div>
            ) : null}
            {currentStep === FORM_STEPS.PASSWORD_CONFIG && (
              <div className="text-right">
                <button
                  type="button"
                  onClick={() => recoverPassword(formik.values.email)}
                  className="text-dark text-hover-primary font-weight-bold border-0 bg-white"
                >
                  <u>
                    <FormattedMessage id="AUTH.GENERAL.RESEND_CODE" />
                  </u>
                </button>
              </div>
            )}
            {currentStep === FORM_STEPS.EMAIL_VALIDATION && (
              <TextField 
                name="email"
                type="email"
                label="Correo"
                placeholder="Correo electrónico"
                touched={formik.touched.email}
                error={formik.errors.email}
                {...formik.getFieldProps("email")}
              />
            )}
            {currentStep === FORM_STEPS.PASSWORD_CONFIG && (
              <>
                <TextField 
                  name="code"
                  label="Código"
                  placeholder="Código de verificación"
                  touched={formik.touched.code}
                  error={formik.errors.code}
                  className="mt-1"
                  {...formik.getFieldProps("code")}
                />
                <TextField 
                  name="password"
                  type="password"
                  label="Contraseña"
                  placeholder="Contraseña nueva"
                  touched={formik.touched.password}
                  error={formik.errors.password}
                  {...formik.getFieldProps("password")}
                />
                <TextField 
                  name="passwordConfirmation"
                  type="password"
                  label="Confirmación de contraseña"
                  placeholder="Confirmación de contraseña"
                  touched={formik.touched.passwordConfirmation}
                  error={formik.errors.passwordConfirmation}
                  {...formik.getFieldProps("passwordConfirmation")}
                />
              </>
            )}
            <div className="form-group d-flex flex-wrap justify-content-between">
              <button
                id="kt_login_forgot_cancel"
                type="button"
                onClick={cancelPasswordRecovery}
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                {STEP_TEXT[currentStep].cancelBtn}
              </button>
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                {STEP_TEXT[currentStep].confirmBtn}
                {loading && <span className="ml-3 spinner spinner-white" />}
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default ForgotPassword;
