import * as Sentry from "@sentry/react";

export const initSentry = (dsn = "", env = "") => {
  return Sentry.init({
    dsn: dsn,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Sets environment
    environment: env,
    // Performance Monitoring
    tracesSampleRate: 0.3, //  Capture 30% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};
