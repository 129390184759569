/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      lastName
      phone
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        lastName
        phone
        email
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPatient = /* GraphQL */ `
  query GetPatient($id: ID!) {
    getPatient(id: $id) {
      id
      name
      lastName
      birthDate
      genre
      phone
      createdByUserId
      createdBy {
        id
        name
        lastName
        phone
        email
        createdAt
        updatedAt
        __typename
      }
      createdAt
      consultations {
        items {
          id
          userID
          patientID
          subject
          diagnosis
          indications
          notes
          createdAt
          updatedAt
        }
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const listPatients = /* GraphQL */ `
  query ListPatients(
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPatients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        lastName
        birthDate
        genre
        phone
        createdByUserId
        createdBy {
          id
          name
          lastName
          phone
          email
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAttachment = /* GraphQL */ `
  query GetAttachment($id: ID!) {
    getAttachment(id: $id) {
      id
      bucket
      region
      key
      consultationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAttachments = /* GraphQL */ `
  query ListAttachments(
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttachments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bucket
        region
        key
        consultationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAilment = /* GraphQL */ `
  query GetAilment($id: ID!) {
    getAilment(id: $id) {
      id
      name
      type
      users {
        items {
          id
          userID
          ailmentID
          description
          createdAt
          updatedAt
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAilments = /* GraphQL */ `
  query ListAilments(
    $filter: ModelAilmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAilments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserAilment = /* GraphQL */ `
  query GetUserAilment($id: ID!) {
    getUserAilment(id: $id) {
      id
      userID
      ailmentID
      user {
        id
        name
        lastName
        phone
        email
        createdAt
        updatedAt
        __typename
      }
      ailment {
        id
        name
        type
        createdAt
        updatedAt
        __typename
      }
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserAilments = /* GraphQL */ `
  query ListUserAilments(
    $filter: ModelUserAilmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAilments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        ailmentID
        user {
          id
          name
          lastName
          phone
          email
          createdAt
          updatedAt
        }
        ailment {
          id
          name
          type
          createdAt
          updatedAt
        }
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConsultation = /* GraphQL */ `
  query GetConsultation($id: ID!) {
    getConsultation(id: $id) {
      id
      userID
      user {
        id
        name
        lastName
        phone
        email
        createdAt
        updatedAt
        __typename
      }
      patientID
      patient {
        id
        name
        lastName
        birthDate
        genre
        phone
        createdByUserId
        createdBy {
          id
          name
          lastName
          phone
          email
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        __typename
      }
      subject
      diagnosis
      indications
      notes
      services {
        items {
          id
          serviceID
          consultationID
          createdAt
          updatedAt
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listConsultations = /* GraphQL */ `
  query ListConsultations(
    $filter: ModelConsultationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsultations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        user {
          id
          name
          lastName
          phone
          email
          createdAt
          updatedAt
        }
        patientID
        patient {
          id
          name
          lastName
          birthDate
          genre
          phone
          createdByUserId
          createdAt
          updatedAt
        }
        subject
        diagnosis
        indications
        notes
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getService = /* GraphQL */ `
  query GetService($id: ID!) {
    getService(id: $id) {
      id
      name
      description
      consultations {
        items {
          id
          serviceID
          consultationID
          createdAt
          updatedAt
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listServices = /* GraphQL */ `
  query ListServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getServiceConsultation = /* GraphQL */ `
  query GetServiceConsultation($id: ID!) {
    getServiceConsultation(id: $id) {
      id
      serviceID
      consultationID
      service {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      consultation {
        id
        userID
        user {
          id
          name
          lastName
          phone
          email
          createdAt
          updatedAt
        }
        patientID
        patient {
          id
          name
          lastName
          birthDate
          genre
          phone
          createdByUserId
          createdAt
          updatedAt
        }
        subject
        diagnosis
        indications
        notes
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listServiceConsultations = /* GraphQL */ `
  query ListServiceConsultations(
    $filter: ModelServiceConsultationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServiceConsultations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serviceID
        consultationID
        service {
          id
          name
          description
          createdAt
          updatedAt
        }
        consultation {
          id
          userID
          patientID
          subject
          diagnosis
          indications
          notes
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      id
      type
      prescription {
        id
        comments
        createdAt
        updatedAt
        __typename
      }
      template {
        id
        name
        description
        attachment {
          id
          bucket
          region
          key
          consultationID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        prescription {
          id
          comments
          createdAt
          updatedAt
        }
        template {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTemplate = /* GraphQL */ `
  query GetTemplate($id: ID!) {
    getTemplate(id: $id) {
      id
      name
      description
      attachment {
        id
        bucket
        region
        key
        consultationID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTemplates = /* GraphQL */ `
  query ListTemplates(
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        attachment {
          id
          bucket
          region
          key
          consultationID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPrescription = /* GraphQL */ `
  query GetPrescription($id: ID!) {
    getPrescription(id: $id) {
      id
      comments
      drugs {
        items {
          id
          drugID
          prescriptionID
          instruction
          createdAt
          updatedAt
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPrescriptions = /* GraphQL */ `
  query ListPrescriptions(
    $filter: ModelPrescriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrescriptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        comments
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDrug = /* GraphQL */ `
  query GetDrug($id: ID!) {
    getDrug(id: $id) {
      id
      name
      activeSubstance
      dose
      unit
      prescriptions {
        items {
          id
          drugID
          prescriptionID
          instruction
          createdAt
          updatedAt
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDrugs = /* GraphQL */ `
  query ListDrugs(
    $filter: ModelDrugFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDrugs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        activeSubstance
        dose
        unit
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDrugPrescription = /* GraphQL */ `
  query GetDrugPrescription($id: ID!) {
    getDrugPrescription(id: $id) {
      id
      drugID
      prescriptionID
      drug {
        id
        name
        activeSubstance
        dose
        unit
        createdAt
        updatedAt
        __typename
      }
      prescription {
        id
        comments
        createdAt
        updatedAt
        __typename
      }
      instruction
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDrugPrescriptions = /* GraphQL */ `
  query ListDrugPrescriptions(
    $filter: ModelDrugPrescriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDrugPrescriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        drugID
        prescriptionID
        drug {
          id
          name
          activeSubstance
          dose
          unit
          createdAt
          updatedAt
        }
        prescription {
          id
          comments
          createdAt
          updatedAt
        }
        instruction
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const patientsByDate = /* GraphQL */ `
  query PatientsByDate(
    $createdByUserId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    patientsByDate(
      createdByUserId: $createdByUserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        lastName
        birthDate
        genre
        phone
        createdByUserId
        createdBy {
          id
          name
          lastName
          phone
          email
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listConsultationsByDate = /* GraphQL */ `
  query ListConsultationsByDate(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConsultationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsultationsByDate(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        user {
          id
          name
          lastName
          phone
          email
          createdAt
          updatedAt
        }
        patientID
        patient {
          id
          name
          lastName
          birthDate
          genre
          phone
          createdByUserId
          createdAt
          updatedAt
        }
        subject
        diagnosis
        indications
        notes
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchPatients = /* GraphQL */ `
  query SearchPatients(
    $filter: SearchablePatientFilterInput
    $sort: SearchablePatientSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPatients(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        lastName
        birthDate
        genre
        phone
        createdByUserId
        createdBy {
          id
          name
          lastName
          phone
          email
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const searchConsultations = /* GraphQL */ `
  query SearchConsultations(
    $filter: SearchableConsultationFilterInput
    $sort: SearchableConsultationSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchConsultations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        userID
        user {
          id
          name
          lastName
          phone
          email
          createdAt
          updatedAt
        }
        patientID
        patient {
          id
          name
          lastName
          birthDate
          genre
          phone
          createdByUserId
          createdAt
          updatedAt
        }
        subject
        diagnosis
        indications
        notes
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
