/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import LogoRedLg from "../../../assets/img/logo-red-lg.png";

export function AuthPage() {
  return (
    <>
      <div className="d-flex flex-column flex-root bg-light">
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          <div className="login-aside container p-10 p-lg-10">
            <div className="row justify-content-center align-items-center h-100">
              <div className="col-6 col-lg-10">
              <Link to="/" className="flex-column-auto">
                <img
                  alt="Logo"
                  className="img-fluid"
                  src={LogoRedLg}
                />
              </Link>
              </div>
            </div>
          </div>

          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                {/* <ContentRoute
                  path="/auth/registration"
                  component={Registration}
                /> */}
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}

          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
