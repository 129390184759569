import React from "react";
import { Skeleton } from "@material-ui/lab";

const TextLabel = ({ label, text = "Sin información", loading = false }) => {
  if (loading) {
    return (
      <div className="mb-6">
        <Skeleton variant="text" width={40} />
        <Skeleton variant="text" width={120} />
      </div>
    );
  }

  return (
    <div className="mb-6">
      <label className="font-weight-bold">{label}</label>
      <p>{text}</p>
    </div>
  );
};

export default TextLabel;