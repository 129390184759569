import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles, Snackbar } from "@material-ui/core";
import { Slide } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useActions } from "../../Shared/hooks";
import * as feedback from "../_redux/feedbackRedux";

export default function Notifier() {
  const classes = useStyles();
  const { open, message, variant } = useSelector(({ feedback }) => feedback);
  const [closeSnackbar] = useActions([feedback.actions.closeSnackbar]);
  const [anchorOrigin] = useState({ vertical: "top", horizontal: "right" });

  return (
    <Snackbar 
      open={open}
      onClose={closeSnackbar}
      autoHideDuration={5000}
      anchorOrigin={anchorOrigin}
      TransitionComponent={props => <Slide {...props} direction="left" />}
      transitionDuration={400}
      ClickAwayListenerProps={{ mouseEvent: false }}
    >
      <Alert variant="filled" severity={variant} className={classes.alert}>{message}</Alert>
    </Snackbar>
  );
}

const useStyles = makeStyles(theme => ({
  alert: {
    fontSize: theme.typography.fontSize,
  }
}))