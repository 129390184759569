export const amplifyVocabulary = {
  "Account recovery requires verified contact information.":
    "La recuperación de la cuenta requiere información de contacto verificada",
  "User does not exist.": "El correo no está registrado",
  "User already exists.": "El correo ya está registrado",
  "Invalid password format.": "Formato de contraseña inválido",
  "Invalid phone number format.":
    "Formato de número de teléfono inválido. Utiliza el formato de número de teléfono +12345678900",
  "Username cannot be empty.": "El nombre de usuario no puede estar vacio",
  "Custom auth lambda trigger is not configured for the user pool.":
    "No esta permitido el inicio de sesión sin contraseña",
  "Incorrect username or password.": "Correo o contraseña incorrecta",
  "Username/client id combination not found.": "El correo no está registrado",
  "Attempt limit exceeded, please try after some time.": "Tiempo de reintentos excedido, intenta nuevamente en unos minutos",
  "Invalid verification code provided, please try again.": "El código de verificación es inválido",
};
