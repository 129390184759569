import React, { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { ConsultationDetailPage } from "./ConsultationDetail/ConsultationDetailPage";
import { ConsultationListPage } from "./ConsultationList/ConsultationListPage";
import { ConsultationFormPage } from "./ConsultationForm/ConsultationFormPage";
import { useSubheader } from "../../../_metronic/layout";

const ConsultationRouting = () => {
  const subheader = useSubheader();
  const location = useLocation();

  useEffect(() => {
    const title = "Consultas";
    subheader.setTitle(title);
  }, [location.pathname]);

  return (
    <Switch>
      <Route path="/consultas/listado" component={ConsultationListPage} />
      <Route path="/consultas/registro/:id?" component={ConsultationFormPage} />
      <Route path="/consultas/detalle/:id" component={ConsultationDetailPage} />
      <Route path="*" render={() => <Redirect to="/consultas/listado" />} />
    </Switch>
  );
};

export default ConsultationRouting;
