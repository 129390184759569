import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { TextField } from "../../Shared/components";

export const ConsultationFormRecipeStep = ({ formik, handleAdd, handleDelete, indications }) => {
  return (
    <div className="card-body">
      <div className="card-title">
        <h3 className="card-label">Indicaciones para la receta médica </h3>
      </div>
      <div className="row mt-9 align-items-center">
        <div className="col">
          <TextField
            name="indication"
            label="Indicación"
            placeholder="Describe la indicación de la receta"
            touched={formik.touched.indication}
            error={formik.errors.indication}
            value={formik.values.indication}
            onChange={formik.handleChange}
          />
        </div>
        <div className="col-auto">
          <button className="btn active btn-primary" onClick={handleAdd}>
            Agregar Indicación
          </button>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-sm pb-5">
          <ul className="list-group">
            <li className="list-group-item d-flex justify-content-between align-items-center">
              <div className="mt-2 ml-1">
                <h6 className="card-label">Lista de indicaciones</h6>
                <span className="text-muted font-weight-bold font-size-sm mt-1"></span>
              </div>
            </li>
            {indications.length ? (
              indications.map((indication, index) => {
                return (
                  <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                    <p className="col-sm mb-0">{`${index + 1}. ${indication}`}</p>
                    <button className="border-0 rounded bg-transparent" onClick={handleDelete(index)}>
                      <span className="svg-icon svg-icon-2x svg-icon-danger">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}></SVG>
                      </span>
                    </button>
                  </li>
                );
              })
            ) : (
              <li className="list-group-item text-muted bg-light">Sin indicaciones registradas</li>
            )}
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <TextField
            name="description"
            component="textarea"
            label="Notas de Indicaciones"
            placeholder="Agregue notas sobre las indicaciones (opcional)"
            touched={formik.touched.description}
            error={formik.errors.description}
            value={formik.values.description}
            onChange={formik.handleChange}
            rows={5}
          />
        </div>
      </div>
    </div>
  );
};
