import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import { useActions } from "../../Shared/hooks";
import * as auth from "../_redux/authRedux";

function Logout() {
  const { hasAuthToken } = useSelector(({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }));
  const [logout] = useActions([auth.actions.logout]);

  useEffect(() => {
    logout();
  }, [logout]);
  
  return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
}

export default Logout;
