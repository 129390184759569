import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

export default function TextField({
  name,
  component,
  type,
  label,
  placeholder,
  value,
  onChange,
  touched,
  error,
  className,
  helper,
  children,
  ...props
}) {
  return (
    <div>
      <Form.Group>
        {label ? <Form.Label className="font-weight-bold">{label}</Form.Label> : null}
        <Form.Control
          name={name}
          as={component}
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={touched && error ? "border-danger" : ""}
          {...props}
        >
          {children}
        </Form.Control>
        {touched && error ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{error}</div>
          </div>
        ) : null}
      </Form.Group>
      {!error && helper && <small className="form-text text-muted">{helper}</small>}
    </div>
  );
}

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  component: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  touched: PropTypes.bool,
  error: PropTypes.string,
  className: PropTypes.string,
};

TextField.defaultProps = {
  name: "",
  component: "input",
  type: "text",
  label: "",
  placeholder: "",
  touched: false,
  className: "",
};
