export function createPatientFilter(searchTerm = "", queryFields = []) {
  let capitalizedLetters = capitalizeFirstLetters(searchTerm);
  let termWithoutAccents = removeAccentsFromVowels(capitalizedLetters);
  return createSearchFilter(queryFields, searchTerm, termWithoutAccents);
}

export function createConsultationFilter(searchTerm = "", queryFields = []) {
  let lowercasedTerm = searchTerm.toLocaleLowerCase();
  let termWithoutAccents = removeAccentsFromVowels(lowercasedTerm);
  return createSearchFilter(queryFields, searchTerm, termWithoutAccents);
}

function createSearchFilter(queryFields, ...searchTerms) {
  return queryFields.reduce(
    (filter, field) => {
      searchTerms.forEach(term => {
        filter.or.push({ [field]: { contains: `${term}` } });
      })
      return filter;
    },
    { or: [] }
  );
}

function capitalizeFirstLetters(text) {
  return text
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
}

function removeAccentsFromVowels(text) {
  // Use normalize() to normalize accents to their base form, then replace accented vowels with their unaccented counterparts
  return text
    .normalize('NFD')
    .replace(/[^\u0000-\u007F]/g, "")
    .replace(/[áàãâä]/gi, 'a')
    .replace(/[éèêë]/gi, 'e')
    .replace(/[íìîï]/gi, 'i')
    .replace(/[óòõôö]/gi, 'o')
    .replace(/[úùûü]/gi, 'u');
}