import React, { useEffect } from "react";
import { Route, Redirect, Switch, useLocation } from "react-router";
import { useSubheader } from "../../../_metronic/layout";
import { UserDetailPage } from "./UserDetail/UserDetailPage";
import UserFormPage from "./UserForm/UserFormPage";
import UserListPage from "./UserList/UserListPage";

export default function UsersPage() {
  const subheader = useSubheader();
  const location = useLocation();

  useEffect(() => {
    const title = "Pacientes"
    subheader.setTitle(title);
  }, [location.pathname]);

  return (
    <Switch>
      <Route exact path="/pacientes/listado" component={UserListPage} />
      <Route exact path="/pacientes/registro" component={UserFormPage} />
      <Route exact path="/pacientes/expediente/:id" component={UserDetailPage} />
      <Route path="*" render={() => <Redirect to="/pacientes/listado" />} />
    </Switch>
  );
}