import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { call, put, takeLatest } from "redux-saga/effects";
import { Logger } from "aws-amplify";
import { getUserByToken, signIn, signOut, requestPasswordChange, changePassword } from "./authCrud";
import { amplifyVocabulary } from "../../../config/internationalization/amplifyVocabulary";
import * as feedback from "../../Feedback/_redux/feedbackRedux";
import { AuthEvent } from "../../Shared/constants/authEvent";

const logger = new Logger("authRedux");

export const actionTypes = {
  Login: "[Login Pending] Action",
  LoginError: "[Login Error] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  ResetUser: "[Reset User] Action",
  RecoverPassword: "[Recover Password] Action",
  RecoverPasswordError: "[Recover Password Error] Action",
  ValidateEmail: "[Validate Email] Action",
  RequestPasswordChange: "[Request Password Change] Action",
  RequestPasswordChangeError: "[Request Password Change Error] Action",
  ChangePassword: "[Change Password] Action",
};

const initialAuthState = {
  user: { id: "", email: "" },
  authToken: "",
  authState: AuthEvent.SIGN_OUT,
  loading: false,
  validEmail: false,
  passwordChanged: false,
  error: "",
};

export const reducer = persistReducer(
  { storage, key: "cp-auth-v1", whitelist: ["user", "authToken", "authState"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        return { ...state, error: "", loading: true };
      }

      case actionTypes.LoginError: {
        const { error } = action.payload;
        return { ...state, error, loading: false };
      }

      case actionTypes.ResetUser: {
        return { ...state, ...action.payload };
      }

      case actionTypes.UserLoaded: {
        const { authToken, user } = action.payload;
        return { ...state, loading: false, error: "", authToken: authToken.jwtToken, user: user, authState: AuthEvent.SIGN_IN };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.RecoverPassword: {
        return { ...state, loading: true, error: "" };
      }

      case actionTypes.RecoverPasswordError: {
        const { error } = action.payload;
        return { ...state, loading: false, error };
      }

      case actionTypes.ValidateEmail: {
        return { ...state, loading: false, error: "", validEmail: true };
      }

      case actionTypes.RequestPasswordChange: {
        return { ...state, loading: true, error: "" };
      }

      case actionTypes.RequestPasswordChangeError: {
        const { error } = action.payload;
        return { ...state, loading: false, error };
      }

      case actionTypes.ChangePassword: {
        return { ...state, loading: false, error: "", passwordChanged: true };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (email, password) => ({ type: actionTypes.Login, payload: { email, password } }),
  invalidateLogin: error => ({ type: actionTypes.LoginError, payload: { error } }),
  fulfillUser: (authToken, user) => ({ type: actionTypes.UserLoaded, payload: { authToken, user } }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  setUser: user => ({ type: actionTypes.SetUser, payload: { user } }),
  resetUser: () => ({ type: actionTypes.ResetUser, payload: initialAuthState }),
  recoverPassword: email => ({ type: actionTypes.RecoverPassword, payload: { email } }),
  invalidateRecoverPassword: error => ({ type: actionTypes.RecoverPasswordError, payload: { error } }),
  validateEmail: () => ({ type: actionTypes.ValidateEmail }),
  requestPasswordChange: (email, code, password) => ({
    type: actionTypes.RequestPasswordChange,
    payload: { email, code, password },
  }),
  invalidatePasswordChange: error => ({
    type: actionTypes.RequestPasswordChangeError,
    payload: { error },
  }),
  changePassword: () => ({ type: actionTypes.ChangePassword }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga({ payload: { email, password } }) {
    try {
      const userLogged = yield call(signIn, email, password);
      // TODO: add support for new users with temporal passowrd
      // if (userLogged.challengeName === "NEW_PASSWORD_REQUIRED") {}
      const user = { 
        email, 
        id: userLogged?.attributes?.sub || "", 
      };
      const accessToken = userLogged?.signInUserSession?.accessToken || "";
      yield put(actions.fulfillUser(accessToken, user));
    } catch (error) {
      logger.error(error);
      const errorMessage = amplifyVocabulary[error.message];
      yield put(actions.invalidateLogin(errorMessage));
    }
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();
    yield put(actions.fulfillUser(user));
  });

  yield takeLatest(actionTypes.Logout, function* logoutUser() {
    try {
      yield call(signOut);
      yield put(actions.resetUser());
    } catch (error) {
      logger.error(error);
    }
  });

  yield takeLatest(actionTypes.RecoverPassword, function* forgotPassword({ payload: { email } }) {
    try {
      yield call(requestPasswordChange, email);
      yield put(actions.validateEmail());
    } catch (error) {
      logger.error(error);
      const errorMessage = amplifyVocabulary[error.message];
      yield put(actions.invalidateRecoverPassword(errorMessage));
    }
  });

  yield takeLatest(actionTypes.RequestPasswordChange, function* updatePassword({ payload }) {
    try {
      const { email, code, password } = payload;
      yield call(changePassword, email, code, password);
      yield put(actions.changePassword());
      yield put(feedback.actions.showSnackbar("La contraseña se cambió de forma exitosa", "success"));
    } catch (error) {
      logger.error(error);
      const errorMessage = amplifyVocabulary[error.message];
      yield put(actions.invalidatePasswordChange(errorMessage));
    }
  });
}
